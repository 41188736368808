.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-image: radial-gradient(circle, #ebf2fe, #060edd);

  main {
    flex: 9 9 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}